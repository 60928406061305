import React, {useState} from 'react';
import "./SearchBar.css";
import { useLocation } from "react-router-dom"; 
export default function SearchBar(props) {
    
    let location = useLocation(); 
    let [ searches ] = useState(new URLSearchParams(location.search).get('q') ?? "*");
      
    const page_url_name = location.pathname.substr(location.pathname.lastIndexOf("/")+1);
    if(localStorage.getItem('advance_search_body') && ( page_url_name=== "search"))
    { 
        const old_search = JSON.parse(localStorage.getItem('advance_search_body'))
        searches = old_search.advsearch;   
    }
    else
    {
        if(searches === '*')
        {
            searches = '';
        } 
    }
    let [q, setQ] = useState(searches);
    const onSearchHandler = () => {
        localStorage.setItem('advance_search_body', '');
        props.postSearchHandler(q); 
    }
  
    const onEnterButton = (event) => {
        if (event.keyCode === 13) {
            onSearchHandler();
        }
    }

    const onChangeHandler = () => {
        var searchTerm = document.getElementById("search-box").value; 
        setQ(searchTerm);

        // use this prop if you want to make the search more reactive
        if (props.searchChangeHandler) {
            props.searchChangeHandler(searchTerm);
        }
    }
 
    return (
        <div >
            <div className="input-group" onKeyDown={e => onEnterButton(e)}>
                <div className="suggestions" >
                    <input 
                        autoComplete="off" // setting for browsers; not the app
                        type="text" 
                        id="search-box" 
                        className="form-control rounded-0"  
                        onChange={onChangeHandler} 
                        defaultValue={q}>
                    </input> 
                </div>
                <div className="input-group-btn">
                    <button className="btn btn-primary rounded-0" type="submit" onClick={onSearchHandler}>
                    <img className="search-icon" src="/images/Search.svg" alt="Search"></img> 
                    </button>
                </div>
            </div>
        </div>
    );
};