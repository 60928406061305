import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// App shell components 
import AppFooter from '../components/AppFooter/AppFooter';

// React Router page components
import Home from '../pages/Home/Home';
import Search from '../pages/Search/Search';
import Details from '../pages/Details/Details';
import AdvanceSearch from '../pages/AdvanceSearch/AdvanceSearch';

// Bootstrap styles, optionally with jQuery and Popper
import 'bootstrap/dist/css/bootstrap.min.css';

// Custom app styles
import './App.css';

export default function App() {
  
  return ( 
      <div className="container-fluid app"> 
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/search" component={Search} />
            <Route path="/advancedsearch" component={AdvanceSearch} />
            <Route path="/details/:id" component={Details} />
          </Switch>
        </Router>
        <AppFooter />
      </div> 
  );
}
