import React from 'react'; 
import './AppHeader.css';

export default function AppHeaderHome(props) {
  
  return (
    <> 
    <span className="top-border"></span>
     <div className="top-header">
        <div className="container">
        <div className="top-header-inner">
          <div className="row d-flex align-items-center">
              <div className="col-sm-3 text-sm-left text-center">
                <a className="navbar-brand" href="/">
                <img className="logo" src="/images/header-logo.png" alt=".."></img>
                </a>
              </div>
              
              <div className="col-sm-9">
              <div className="advance-search text-sm-right text-center">
                <a href="/advancedsearch"> <img className="filter" src="/images/Filters.svg" alt="filter"></img> Advanced Search</a>
              </div>
              </div>
            </div>
          </div>
        </div>
     </div>
   </>
  );
};
