import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress  from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import AppHeader from '../../components/AppHeader/AppHeader';
import Results from '../../components/Results/Results';
import Pager from '../../components/Pager/Pager';  

import "./Search.css";

export default function Search() {
  
  let location = useLocation(); 
  
  const [ results, setResults ] = useState([]);
  const [ resultCount, setResultCount ] = useState(0);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ search ] = useState(new URLSearchParams(location.search).get('q') ?? "*");
  const [ top ] = useState(new URLSearchParams(location.search).get('top') ?? 10);
  const [ skip, setSkip ] = useState(new URLSearchParams(location.search).get('skip') ?? 0);
  const [ filters ] = useState([]); 
  const [ isLoading, setIsLoading ] = useState(true);

  let resultsPerPage = top;

  useEffect(() => {
    var retrievedObject = localStorage.getItem('advance_search_body'); 
    let advance_search_body ='';
    let s_body = '';
    setIsLoading(true); 
    if(retrievedObject && retrievedObject !== '')
    { 
       advance_search_body = JSON.parse(retrievedObject); 
        let search_keyword = (advance_search_body.advsearch) ? advance_search_body.advsearch : '';
        let SearchMode = (advance_search_body.SearchMode) ? advance_search_body.SearchMode : '';
        let filter_data = {
            permissions: advance_search_body.security_field,
            DocumentDate: {start: advance_search_body.doc_date.start, end: advance_search_body.doc_date.end},
            ModifiedDate: {start: advance_search_body.modified_date.start, end: advance_search_body.modified_date.end}
        };  
        let final_doc_filtered = advance_search_body.document_fields.concat(advance_search_body.highlight_fields);
         

        final_doc_filtered = final_doc_filtered.filter((item,index)=>{
            return (final_doc_filtered.indexOf(item) === index)
        }) 
        const static_array = ['content', 
            'metadata_storage_name', 
            'metadata_storage_path', 
            'metadata_content_type', 
            'metadata_storage_name', 
            'metadata_title',
            'people', 
            'organizations', 
            'locations', 
            'keyphrases', 
            'filename', 
            'URL', 
            'Accessionnumber', 
            'AddresseeAffiliation', 
            'AddresseeName', 
            'AuthorName',
            'AuthorAffiliation', 
            'CaseReferenceNumber', 
            'DocumentDate', 
            'lastmodified', 
            'DocketNumber', 
            'DocumentTitle', 
            'DocumentType', 
            'Keywords', 
            'LicenseNumber', 
            'PackageNumber', 
            'permissions']
        let arr1 = static_array;
        let arr2 = advance_search_body.extra_fields;
        let b = new Set(arr2);
        let extra_difference = [...arr1].filter(x => !b.has(x)); 
 
       s_body = {
        search: search_keyword,
        queryType: "simple",
        searchMode: SearchMode,
        searchFields: final_doc_filtered,
        select: extra_difference,
        count: "true",
        skip:0,
        ci: advance_search_body.searchIndex,
        top:10, 
        highlight: advance_search_body.highlight_fields.join(','), 
        highlightPreTag: "<mark>",  
        highlightPostTag: "</mark>",
        filters: filter_data
      } 
          
    }
    else{
      s_body = {
        search: search, 
        ci: '',
        top: top,
        skip: skip,
        filters: filters
      };
    } 
    axios.post( '/api/search', s_body)
        .then( response => {
            setResults(response.data.results); 
            setResultCount(response.data.count);
            setIsLoading(false); 
        } )
        .catch(error => {
            console.log(error);
            setIsLoading(false);
        });  
  }, [search, top, skip, filters]);
 

  let updatePagination = (newPageNumber) => {
    setCurrentPage(newPageNumber);
    setSkip((newPageNumber-1) * top);
  }

  var body;
  if (isLoading) {
    body = (
      <div className="col-md-12 loader-outer text-center">
        <CircularProgress className="mt-5 " />
      </div>);
  } else {
    body = (
      <div className="col-md-12"> 
        <Results documents={results} top={top} skip={skip} count={resultCount}></Results>
        <Pager className="pager-style" currentPage={currentPage} resultCount={resultCount} resultsPerPage={resultsPerPage} setCurrentPage={updatePagination}></Pager>
      </div>
    )
  }

  return (
    <main className="main main--search">
      <AppHeader />
      <div className="container">
      <div className="row"> 
        {body}
      </div>
      </div>
    </main>
  );
}
