import React, {useState, useEffect} from 'react';

import './Pager.css';

export default function Pager(props) {
    
    let [selectedPage, setSelectedPage] = useState(parseInt(props.currentPage));
    let totalPages = Math.ceil(props.resultCount / props.resultsPerPage); 

    useEffect(_=>{
        props.setCurrentPage(selectedPage);
    }, [selectedPage, props]);

    function goToNextPage() {
        setSelectedPage(selectedPage + 1);
    }

    function goToPreviousPage() {
        setSelectedPage(selectedPage - 1);
    }

    var i = 0;
    var page_links = [];

    var minPage = 1;
    var maxPage = totalPages;
    if (selectedPage - minPage > 2) {
        minPage = selectedPage - 2;
    }

    if (maxPage - selectedPage > 2) {
        maxPage = parseInt(selectedPage) + 2;
    }


    for (i = minPage; i <= maxPage; i++) {
        if (i === parseInt(selectedPage)) {
            page_links.push(
                <li className="page-item active" key={i}>
                    <span className="page-link">
                        {i}
                    </span>
                </li>
            );
        } else {
            page_links.push(
                 
                <li className="page-item" key={i}>
                <button className="page-link" id={i} onClick={(e) => setSelectedPage(parseInt(e.currentTarget.id))}>{i}  
                </button>
                </li>
            );
        }
    } 
    var previousButton;
    if (parseInt(selectedPage) === 1) {
        previousButton = (<li className="page-item disabled" key="prev">
                            <span className="preiview_class"></span>
                        </li>);
    } else {
        previousButton = (<li className="page-item" key="prev" onClick={goToPreviousPage}>
                            <button className="preiview_class"></button>
                        </li>);
    }

    var nextButton;
    if (parseInt(selectedPage) === totalPages) {
        nextButton = (<li className="page-item disabled" key="next">
                            <span className="next_class"></span>
                        </li>);
    } else {
        nextButton = (<li className="page-item" key="next" >
                            <button className="next_class" onClick={goToNextPage}></button>
                        </li>);
    }
    var midnumber; 
    if(totalPages > maxPage) 
    {
        midnumber = (<>
                <li>
                        <span className="page-link custom_class">... </span>
                    </li>
                    <li>
                        <span className="page-link custom_class">{totalPages.toLocaleString("en-US")} </span> 
                    </li>
                    </>
                    )
    } 
                    
    if(totalPages > 0)
    { 
        return (
            <nav aria-label="..."  className="pager">
                <ul className="pagination item">
                    {previousButton}
                    {page_links}
                    {midnumber}
                    {nextButton}
                </ul>
            </nav>
        );
    }
    else{
        return (
             <div></div>
        );
    }
    }