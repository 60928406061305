import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import AppHeaderHome from '../../components/AppHeader/AppHeaderHome'; 
import {FormGroup, FormControlLabel, Checkbox, FormControl, Select, Button, TextField, Dialog, DialogContent, DialogTitle } from '@material-ui/core/';
import "./AdvanceSearch.css";

var getOldData =  (localStorage.getItem('advance_search_body')) ? JSON.parse(localStorage.getItem('advance_search_body')) : ''; 
let  oldsearchedVal = (getOldData) ? getOldData.searchedVal : '' 
  
const blankState = {
  DocumentTitle: false, content: false, AddresseeName: false, Accessionnumber: false, AddresseeAffiliation: false,
  DocketNumber: false, AuthorName: false, LicenseNumber: false, AuthorAffiliation: false, CaseReferenceNumber: false,
  PackageNumber: false, DocumentType: false, 
  HDocumentTitle: false, Hcontent: false, HAddresseeName: false, HAccessionnumber: false, HAddresseeAffiliation: false, HDocketNumber: false, HAuthorName: false, HLicenseNumber: false, HAuthorAffiliation: false, HCaseReferenceNumber: false, HPackageNumber: false, HDocumentType: false,
  keyphrases: false, Organizations: false, people: false, locations: false, filename: false, words: "", partial_words: "", without_words: "", must_words: "", SearchIndex2: "cs-mvp-noskills", SearchMode: "any", Security_Group_Filter: "groupa", startDate: "", endDate: "", modifiedDate: "", endmodifiedDate: "", DocAll: false, ExtAll: false, HighAll: false
}; 

let  initialState = (getOldData) ? oldsearchedVal : blankState;

let docarr = ['DocumentTitle', 'content', 'AddresseeName', 'Accessionnumber', 'AddresseeAffiliation', 'DocketNumber', 'AuthorName', 'LicenseNumber', 'AuthorAffiliation', 'CaseReferenceNumber', 'PackageNumber', 'DocumentType'];
let extarr = ['keyphrases', 'Organizations', 'people', 'locations', 'filename'];
let higharr = ['HDocumentTitle', 'Hcontent', 'HAddresseeName', 'HAccessionnumber', 'HAddresseeAffiliation', 'HDocketNumber', 'HAuthorName', 'HLicenseNumber', 'HAuthorAffiliation', 'HCaseReferenceNumber', 'HPackageNumber', 'HDocumentType'];
   
export default function AdvanceSearch(props) {  
  let history = useHistory();  

  const [ { DocumentTitle, content, AddresseeName, Accessionnumber, AddresseeAffiliation, DocketNumber, AuthorName, LicenseNumber, AuthorAffiliation, CaseReferenceNumber, PackageNumber, DocumentType, HDocumentTitle, Hcontent, HAddresseeName, HAccessionnumber, HAddresseeAffiliation, HDocketNumber, HAuthorName, HLicenseNumber, HAuthorAffiliation, HCaseReferenceNumber, HPackageNumber, HDocumentType, keyphrases, Organizations, people, locations, filename,
  words, partial_words, without_words, must_words, SearchIndex2, SearchMode, Security_Group_Filter, startDate, endDate, modifiedDate, endmodifiedDate, DocAll, ExtAll, HighAll },  setState ] = useState(initialState);
  const [open, setOpenPopup] = useState(false);
  const onAdvChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onAdvChangeCheckbox = (e) => {  
    let { name, checked } = e.target; 
    checked = (checked) ? true : false; 
    setState((prevState) => ({ ...prevState, [name]: checked }));
  };
  
  const onSelectAll = (e) => { 
    let { name, checked } = e.target; 
    checked = (checked) ? true : false; 
    setState((prevState) => ({ ...prevState, [name]: checked })); 
    
     let checkAllarr = [];
    if(name === 'DocAll')
    {
      checkAllarr = docarr;
    }
    else if(name === 'ExtAll')
    {
      checkAllarr = extarr;
    }
    else if(name === 'HighAll')
    {
      checkAllarr = higharr;
    }  
    for(const val of checkAllarr)
    { 
      setState((prevState) => ({ ...prevState, [val]: checked }));
    } 
  }
  const ResetFields = (event) => {  
    document.getElementById(event).value = ""; 
    setState((prevState) => ({ ...prevState, [event]: '' })); 
  }
 
  const handleSubmit = async (event) => {
    event.preventDefault();  
    // create search query start
    let search_keyword = '';
    if(words !=='' && words !== undefined)
    {
      search_keyword += words;
    }
    if(partial_words !=='' && partial_words !== undefined)
    { 
      let p_words = '';
      //searchMode = "any";
      const total_String = partial_words.split(" "); 
      for (const  product of total_String) {
        if(product !=='') 
        p_words += " "+product+"*";
      }
      search_keyword += p_words;
    }
    if(must_words !=='' && must_words !== undefined)
    { 
      let m_words = '';
      const total_String = must_words.split(" "); 
      for (const product of total_String) {
        if(product !=='') 
        m_words += " +"+product;
      }
      search_keyword += m_words;
    }
    if(without_words !=='' && without_words !== undefined)
    {
      let w_words = '';
      const total_String = without_words.split(" "); 
      for (const product of total_String) {
        if(product !=='') 
        w_words += " -"+product;
      }
      search_keyword += w_words;
    } 
    // create search query end

    let Document_fields = [];
    if(DocumentTitle) { Document_fields.push('DocumentTitle'); }
    if(content) {  Document_fields.push('content'); }
    if(AuthorAffiliation) { Document_fields.push('AuthorAffiliation'); }
    if(AddresseeAffiliation) {  Document_fields.push('AddresseeAffiliation'); }
    if(AuthorName) { Document_fields.push('AuthorName'); }
    if(CaseReferenceNumber) {  Document_fields.push('CaseReferenceNumber'); }
    if(DocketNumber) { Document_fields.push('DocketNumber'); }
    if(DocumentType) {  Document_fields.push('DocumentType'); }
    if(LicenseNumber) { Document_fields.push('LicenseNumber'); }
    if(PackageNumber) {  Document_fields.push('PackageNumber'); }
    if(Accessionnumber) { Document_fields.push('Accessionnumber'); }
    if(AddresseeName) { Document_fields.push('AddresseeName'); }
    
    let Highlight_fields = [];
    if(HDocumentTitle) { Highlight_fields.push('DocumentTitle'); }
    if(Hcontent) {  Highlight_fields.push('content'); }
    if(HAuthorAffiliation) { Highlight_fields.push('AuthorAffiliation'); }
    if(HAddresseeAffiliation) {  Highlight_fields.push('AddresseeAffiliation'); }
    if(HAuthorName) { Highlight_fields.push('AuthorName'); }
    if(HCaseReferenceNumber) {  Highlight_fields.push('CaseReferenceNumber'); }
    if(HDocketNumber) { Highlight_fields.push('DocketNumber'); }
    if(HDocumentType) {  Highlight_fields.push('DocumentType'); }
    if(HLicenseNumber) { Highlight_fields.push('LicenseNumber'); }
    if(HPackageNumber) {  Highlight_fields.push('PackageNumber'); }
    if(HAccessionnumber) { Highlight_fields.push('Accessionnumber'); }
    if(HAddresseeName) { Highlight_fields.push('AddresseeName'); }

    let extra_fields = [];
    if(keyphrases) { extra_fields.push('keyphrases'); }
    if(people) {  extra_fields.push('people'); }
    if(Organizations) { extra_fields.push('Organizations'); }
    if(locations) {  extra_fields.push('locations'); }
    if(filename) {  extra_fields.push('filename'); }

    const searchIndex = SearchIndex2;

    const security_field = Security_Group_Filter;
    const search_words = {
      words: words,
      partial_words: partial_words,
      must_words: must_words,
      without_words: without_words
    }

    const doc_date = {
      start: startDate,
      end: endDate
    }

    const modified_date = {
      start : modifiedDate,
      end  : endmodifiedDate
    } 
 
    const searchedVal = {
      DocumentTitle: DocumentTitle, content: content, AddresseeName: AddresseeName, Accessionnumber: Accessionnumber, AddresseeAffiliation: AddresseeAffiliation,
      DocketNumber: DocketNumber, AuthorName: AuthorName, LicenseNumber: LicenseNumber, AuthorAffiliation: AuthorAffiliation, CaseReferenceNumber: CaseReferenceNumber,
      PackageNumber: PackageNumber, DocumentType: DocumentType, 
      HDocumentTitle: HDocumentTitle, Hcontent: Hcontent, HAddresseeName: HAddresseeName, HAccessionnumber: HAccessionnumber, HAddresseeAffiliation: HAddresseeAffiliation, HDocketNumber: HDocketNumber, HAuthorName: HAuthorName, HLicenseNumber: HLicenseNumber, HAuthorAffiliation: HAuthorAffiliation, HCaseReferenceNumber: HCaseReferenceNumber, HPackageNumber: HPackageNumber, HDocumentType: HDocumentType,
      keyphrases: keyphrases, Organizations: Organizations, people: people, locations: locations, filename: filename, words: words, partial_words: partial_words, without_words: without_words, must_words: must_words, SearchIndex2: SearchIndex2, SearchMode: SearchMode,  Security_Group_Filter: Security_Group_Filter, startDate: startDate, endDate: endDate, modifiedDate: modifiedDate, endmodifiedDate: endmodifiedDate, DocAll: DocAll, ExtAll: ExtAll, HighAll: HighAll
    }; 
    const all_data = {
      advsearch: search_keyword,
      search_words: search_words,
      document_fields: Document_fields, 
      highlight_fields: Highlight_fields,
      extra_fields: extra_fields,
      searchIndex: searchIndex,
      SearchMode: SearchMode,
      security_field: security_field,
      doc_date: doc_date,
      modified_date: modified_date,
      searchedVal: searchedVal
    } 
    localStorage.setItem("advance_search_body", JSON.stringify(all_data));
    history.push('/search'); 
  }
  const searchFromReset = () => { 
    localStorage.setItem('advance_search_body', '');
    document.getElementById("Adsearch").reset();
    setState({ ...initialState });
    window.location.reload();
  } 
  const advancedOptionPopup = () => { 
    setOpenPopup(true);
  }
  const handleClose = () => { 
    setOpenPopup(false);
  }; 
  return (
    <main className="main main--search advance-search-page">
      <AppHeaderHome />
      <div className="container">
        <h2 className="advance-search text-center my-5">Advanced Search</h2>
        <form onSubmit={handleSubmit}  id="Adsearch">
          <div className="row d-flex mb-4 ad-find-search">
            <div className="col-sm-6">
              <h5 className="find-document mb-0 mt-2">Find Documents With...</h5>
            </div>
            <div className="col-sm-6 text-right">
              <span className="ad-search-opt-btn btn btn-info" onClick={advancedOptionPopup}>Advanced Search Options</span>
            </div>
          </div> 
          <div className="row mb-3 FindDocumentsInput">
            <div className="col-lg-4 text-right pt-3">These words included</div>
            <div className="col-lg-8">
              <TextField id="outlined-basic" variant="outlined" onChange={onAdvChange} name="words" value={words} />
            </div>
          </div>
          <div className="row mb-3 FindDocumentsInput">
            <div className="col-lg-4 text-right pt-3">These partial words included</div>
            <div className="col-lg-8">
              <TextField id="outlined-basic1" variant="outlined" onChange={onAdvChange} name="partial_words" value={partial_words} />
            </div>
          </div>
          <div className="row mb-3 FindDocumentsInput">
            <div className="col-lg-4 text-right pt-3">These words that must be in the results</div>
            <div className="col-lg-8">
              <TextField id="outlined-basic2" variant="outlined" onChange={onAdvChange} name="must_words" value={must_words} />
            </div>
          </div>
          <div className="row mb-3 FindDocumentsInput">
            <div className="col-lg-4 text-right pt-3">Without these words</div>
            <div className="col-lg-8">
              <TextField id="outlined-basic3" variant="outlined" onChange={onAdvChange} name="without_words" value={without_words} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 text-right document-date-sec">The document date ranging from</div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-sm-5 FindDocumentsInput pr-sm-0">
                  <TextField id="startDate" name="startDate" type="date" onChange={onAdvChange}  value={startDate}
                    variant="outlined" InputLabelProps={{shrink: true}}
                    inputProps={{  max: endDate }} />
                  {startDate && <span className="ad-date-reset-btn" onClick={() => ResetFields('startDate')}>✕</span> }
                </div>
                <div className="col-sm-2 text-center mt-3 extra-section">to</div> 
                <div className="col-sm-5 FindDocumentsInput pl-sm-0">
                  <TextField id="endDate" name="endDate" type="date" onChange={onAdvChange}  value={endDate}
                    variant="outlined" InputLabelProps={{shrink: true}}
                    inputProps={{  min: startDate }} />
                  {endDate && <span className="ad-date-reset-btn ex-space" onClick={() => ResetFields('endDate')}>✕</span> }
                </div>       
              </div>       
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4 text-right document-date-sec">The last modified date ranging from</div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-sm-5 FindDocumentsInput pr-sm-0">
                  <TextField id="modifiedDate" name="modifiedDate" type="date" onChange={onAdvChange}  value={modifiedDate}
                    variant="outlined" InputLabelProps={{shrink: true}} 
                    inputProps={{  max: endmodifiedDate }} />
                  {modifiedDate && <span className="ad-date-reset-btn" onClick={() => ResetFields('modifiedDate')}>✕</span> }
                </div>
                <div className="col-sm-2 text-center mt-3 extra-section">to</div> 
                <div className="col-sm-5 FindDocumentsInput pl-sm-0">
                    <TextField id="endmodifiedDate" name="endmodifiedDate" type="date" onChange={onAdvChange}  value={endmodifiedDate}
                    variant="outlined" InputLabelProps={{shrink: true}} 
                    inputProps={{  min: modifiedDate }}/>
                    {endmodifiedDate && <span className="ad-date-reset-btn ex-space" onClick={() => ResetFields('endmodifiedDate')}>✕</span> }
                  </div>       
              </div> 
            </div>
          </div>
           
          <div className="row mt-5 search-row">
            <div className="col-sm-12 text-center"> 
              <Button className="search-btn" variant="contained" type="submit" color="primary" size="large">Search</Button>
              <Button className="search-btn" variant="contained" type="button" color="primary" size="large" onClick={searchFromReset}>Reset</Button>
            </div>
          </div>
        </form>
        <div className="height-window"></div>
      </div>
      {open && <Dialog className="advanced-search-popup" open={open} onClose={handleClose} aria-labelledby="form-dialog-title"> 
          <DialogTitle id="form-dialog-title" className="size-larger">Advanced Search Options
            <span onClick={handleClose} className="float-right" color="primary"><img src='/images/Close.svg' width="15" alt="img"/></span>
          </DialogTitle>            
          <DialogContent> 
            <div className="popup-body-content">
              <div className="row selectAdvancedField"> 
                <div className="col-lg-6">
                  <h5 className="adv-check-title"><FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox checked={DocAll} onChange={onSelectAll} name="DocAll" color="primary" value="DocAll" />
                      }
                      label="Document Fields" /> 
                    </FormGroup>
                  </h5>
                  <div className="row row-cols-2 mx-0"> 
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={DocumentTitle} onChange={onAdvChangeCheckbox} name="DocumentTitle" color="primary" value="DocumentTitle" className="dcmntcheck" />
                        }
                        label="Document Title" />
                    </FormGroup> 
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={content} onChange={onAdvChangeCheckbox} name="content" color="primary" value='content' className="dcmntcheck" />
                        }
                        label="Document Body" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={AddresseeName} onChange={onAdvChangeCheckbox} name="AddresseeName" color="primary" value="AddresseeName" className="dcmntcheck" />
                        }
                        label="Addressee Name" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={Accessionnumber} onChange={onAdvChangeCheckbox} name="Accessionnumber" color="primary" value="Accessionnumber" className="dcmntcheck" />
                        }
                        label="Accession Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox  checked={AddresseeAffiliation} onChange={onAdvChangeCheckbox} name="AddresseeAffiliation" color="primary" value="AddresseeAffiliation" />
                        }
                        label="Addressee Affiliation" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={DocketNumber} onChange={onAdvChangeCheckbox} name="DocketNumber" color="primary" value="DocketNumber" />
                        }
                        label="Docket Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={AuthorName} onChange={onAdvChangeCheckbox} name="AuthorName" color="primary" value="AuthorName" />
                        }
                        label="Author Name" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={LicenseNumber} onChange={onAdvChangeCheckbox} name="LicenseNumber" color="primary" value="LicenseNumber" />
                        }
                        label="License Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={AuthorAffiliation} onChange={onAdvChangeCheckbox} name="AuthorAffiliation" color="primary" value="AuthorAffiliation" />
                        }
                        label="Author Affiliation" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={CaseReferenceNumber} onChange={onAdvChangeCheckbox} name="CaseReferenceNumber" color="primary" value="CaseReferenceNumber" />
                        }
                        label="Case Reference Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={DocumentType} onChange={onAdvChangeCheckbox} name="DocumentType" color="primary" value="DocumentType" />
                        }
                        label="Document Type" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={PackageNumber} onChange={onAdvChangeCheckbox} name="PackageNumber" color="primary" value="PackageNumber" />
                        }
                        label="Package Number" />
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-sm-6 select-extracted-field">
                      <h5 className="adv-check-title">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox checked={ExtAll} onChange={onSelectAll} name="ExtAll" color="primary" value="ExtAll" />
                            }
                            label="Extracted Fields" />
                        </FormGroup>
                      </h5>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox checked={keyphrases} onChange={onAdvChangeCheckbox} name="keyphrases" color="primary" value="keyphrases" />
                          }
                          label="Key Phrases" />
                      </FormGroup>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox checked={people} onChange={onAdvChangeCheckbox} name="people" color="primary" value="people" />
                          }
                          label="People" />
                      </FormGroup>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox checked={Organizations} onChange={onAdvChangeCheckbox} name="Organizations" color="primary" value="organizations" />
                          }
                          label="Organizations"
                        />
                      </FormGroup>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox checked={locations} onChange={onAdvChangeCheckbox} name="locations" color="primary" value="locations" />
                          }
                          label="Locations"
                        />
                      </FormGroup>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox checked={filename} onChange={onAdvChangeCheckbox} name="filename" color="primary" value="filename" />
                          }
                          label="File Name" />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 select-input">
                      <h5 className="pt-2 mb-3">Search Index</h5> 
                      <FormControl variant="outlined"> 
                        <Select native onChange={onAdvChange} name="SearchIndex2" value={SearchIndex2}>
                          <option value="cs-mvp-noskills" defaultValue="cs-mvp-noskills">INDEX WITH NO SKILLS</option>
                          <option value="cs-mvp">INDEX WITH SKILLS – 2018 only</option>
                        </Select> 
                      </FormControl> 
                      <h5 className="my-3">Search Mode</h5> 
                      <FormControl variant="outlined"> 
                        <Select native onChange={onAdvChange} name="SearchMode" value={SearchMode}>
                          <option value="any" defaultValue="any">Any</option>
                          <option value="all">All</option>
                        </Select> 
                      </FormControl>
                      <h5 className="my-3">Security Group Filter</h5>
                      <FormControl variant="outlined"> 
                        <Select native onChange={onAdvChange} name="Security_Group_Filter" value={Security_Group_Filter}>
                          <option value="groupa" defaultValue="groupa">Groupa</option>
                          <option value="groupb">Groupb</option>
                          <option value="groupc">Groupc</option>
                        </Select> 
                      </FormControl>                    
                    </div>
                  </div>
                </div> 
                <div className="col-lg-12 mt-5">
                  <h5 className="adv-check-title">
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HighAll} onChange={onSelectAll} name="HighAll" color="primary" value="HighAll" />
                        }
                        label="Highlight Fields" />
                    </FormGroup>
                  </h5>
                  <div className="row row-cols-lg-4 row-cols-sm-2  row-cols-1 mx-0"> 
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HDocumentTitle} onChange={onAdvChangeCheckbox} name="HDocumentTitle" color="primary" value="DocumentTitle" />
                        }
                        label="Document Title" />
                    </FormGroup> 
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={Hcontent} onChange={onAdvChangeCheckbox} name="Hcontent" color="primary" value='content' />
                        }
                        label="Document Body" />
                    </FormGroup> 
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HAddresseeName} onChange={onAdvChangeCheckbox} name="HAddresseeName" color="primary" value="AddresseeName" />
                        }
                        label="Addressee Name"
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HAccessionnumber} onChange={onAdvChangeCheckbox} name="HAccessionnumber" color="primary" value="Accessionnumber" />
                        }
                        label="Accession Number"
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HAddresseeAffiliation} onChange={onAdvChangeCheckbox} name="HAddresseeAffiliation" color="primary" value="AddresseeAffiliation" />
                        }
                        label="Addressee Affiliation" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HDocketNumber} onChange={onAdvChangeCheckbox} name="HDocketNumber" color="primary" value="DocketNumber" />
                        }
                        label="Docket Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HAuthorName} onChange={onAdvChangeCheckbox} name="HAuthorName" color="primary" value="AuthorName" />
                        }
                        label="Author Name" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HLicenseNumber} onChange={onAdvChangeCheckbox} name="HLicenseNumber" color="primary" value="LicenseNumber" />
                        }
                        label="License Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HAuthorAffiliation} onChange={onAdvChangeCheckbox} name="HAuthorAffiliation" color="primary" value="AuthorAffiliation" />
                        }
                        label="Author Affiliation" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox  checked={HCaseReferenceNumber} onChange={onAdvChangeCheckbox} name="HCaseReferenceNumber" color="primary" value="CaseReferenceNumber" />
                        }
                        label="Case Reference Number" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HDocumentType} onChange={onAdvChangeCheckbox} name="HDocumentType" color="primary" value="DocumentType" />
                        }
                        label="Document Type" />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox checked={HPackageNumber} onChange={onAdvChangeCheckbox} name="HPackageNumber" color="primary" value="PackageNumber" />
                        }
                        label="Package Number" />
                    </FormGroup>
                  </div>
                
                </div>
                <Button className="search-btn mt-5 mb-4 mx-auto" variant="contained" type="button" color="primary" size="large" onClick={handleClose}>Save</Button>
              </div> 
            </div> 
          </DialogContent>
        </Dialog>
      }
    </main>
  );
}
