import React from 'react';

import './AppFooter.css';

export default function AppFooter() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-sm-6">
              <div className="footer-left-sec text-sm-left text-center">
              <p>©2021 TekSynap; all rights reserved. <a href="/">(Corporate Proprietary)</a></p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="footer-right-sec text-sm-right text-center">
              <p>Powered by: <a href="/"><img src="images/Footer-TekXlerate.png" alt=".."></img></a></p>
              </div>
            </div>
          </div>
        </div>
        <span className="bottom-border"></span>
      </footer>
      

      </>
  );
};
