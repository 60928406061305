import React, { useState } from "react";
import CircularProgress  from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat';
import "./Results.css";

export default function Results(props) {
    window.scrollTo(0, 0)
    const [ isLoading, setIsLoading ] = useState(false);
   
    const [open, setOpen] = React.useState(false);
    function changeDate (date)
    {
        let current_days = dateFormat(date, "dddd, mmmm d, yyyy");
        return current_days;
    } 
  const [singledocument, setDocument] = useState({});     
  async function openDetailPopup (id)
  { 
        setIsLoading(true);
        const item = props.documents.filter(word => word.document.metadata_storage_name === id);  
        let DocumentTitle = item[0].document.DocumentTitle;
        if (item[0].highlights && item[0].highlights.DocumentTitle) 
        { 
            DocumentTitle = <UnsafeComponent html={item[0].highlights.DocumentTitle.join(', ')}/>;
        }
        let content = item[0].document.content;
        if (item[0].highlights && item[0].highlights.content) 
        { 
            content = <UnsafeComponent html={item[0].highlights.content.join(', ')}/>;
        } 
        let Accessionnumber = item[0].document.Accessionnumber;
        if (item[0].highlights && item[0].highlights.Accessionnumber) 
        { 
            Accessionnumber = <UnsafeComponent html={item[0].highlights.Accessionnumber.join(', ')}/>;
        }
        let AddresseeName = item[0].document.AddresseeName;
        if (item[0].highlights && item[0].highlights.AddresseeName) 
        { 
            AddresseeName = <UnsafeComponent html={item[0].highlights.AddresseeName.join(', ')}/>;
        }
        let AddresseeAffiliation = item[0].document.AddresseeAffiliation;
        if (item[0].highlights && item[0].highlights.AddresseeAffiliation) 
        { 
            AddresseeAffiliation = <UnsafeComponent html={item[0].highlights.AddresseeAffiliation.join(', ')}/>;
        }
        let DocketNumber = item[0].document.DocketNumber;
        if (item[0].highlights && item[0].highlights.DocketNumber) 
        { 
            DocketNumber = <UnsafeComponent html={item[0].highlights.DocketNumber.join(', ')}/>;
        }
        let AuthorName = item[0].document.AuthorName;
        if (item[0].highlights && item[0].highlights.AuthorName) 
        {   
            AuthorName = <UnsafeComponent html={item[0].highlights.AuthorName.join(', ')}/>;
        }
        let LicenseNumber = item[0].document.LicenseNumber;
        if (item[0].highlights && item[0].highlights.LicenseNumber) 
        { 
            LicenseNumber = <UnsafeComponent html={item[0].highlights.LicenseNumber.join(', ')}/>;
        }
        let AuthorAffiliation = item[0].document.AuthorAffiliation;
        if (item[0].highlights && item[0].highlights.AuthorAffiliation) 
        { 
            AuthorAffiliation = <UnsafeComponent html={item[0].highlights.AuthorAffiliation.join(', ')}/>;
        }
        let CaseReferenceNumber = item[0].document.CaseReferenceNumber;
        if (item[0].highlights && item[0].highlights.CaseReferenceNumber) 
        { 
            CaseReferenceNumber = <UnsafeComponent html={item[0].highlights.CaseReferenceNumber.join(', ')}/>;
        }
        let DocumentType = item[0].document.DocumentType;
        if (item[0].highlights && item[0].highlights.DocumentType) 
        { 
            DocumentType = <UnsafeComponent html={item[0].highlights.DocumentType.join(', ')}/>;
        }
        let PackageNumber = item[0].document.PackageNumber;
        if (item[0].highlights && item[0].highlights.PackageNumber) 
        { 
            PackageNumber = <UnsafeComponent html={item[0].highlights.PackageNumber.join(', ')}/>;
        }                
        let data_array = { 
            Accessionnumber: Accessionnumber,
            AddresseeAffiliation: AddresseeAffiliation,
            AddresseeName: AddresseeName,
            AuthorAffiliation: AuthorAffiliation,
            AuthorName: AuthorName,
            CaseReferenceNumber: CaseReferenceNumber,
            DocketNumber: DocketNumber,
            DocumentDate: item[0].document.DocumentDate,
            DocumentTitle: DocumentTitle,
            DocumentType: DocumentType,
            Keywords: item[0].document.Keywords,
            LicenseNumber: LicenseNumber,
            PackageNumber: PackageNumber,
            URL: item[0].document.URL,
            content: content,
            filename: item[0].document.filename,
            lastmodified: item[0].document.lastmodified,
        }
        setDocument(data_array); 
        setIsLoading(false);
        setOpen(true);
  } 
    const handleClose = () => { 
        setOpen(false);
    }; 
  const UnsafeComponent = (html) => { 
    return <div className="project_description_div" dangerouslySetInnerHTML={{ __html: html.html }} />;
  }  
  let results = props.documents.map((result, index) => {  
    let DocumentTitle = result.document.DocumentTitle;
    if (result.highlights && result.highlights.DocumentTitle) 
    { 
        DocumentTitle = <UnsafeComponent html={result.highlights.DocumentTitle.join(', ')}/>;
    } 
    return( 
      <div className="col-xs-12 col-sm-12 mb-5 result_inner" key={index}> 
            <h5> 
                <span className="document_pdf_link" onClick={()=> window.open(`https://www.nrc.gov${result.document.URL}`,'winname','directories=left, titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=1000 height=450px' )}>
                    {(DocumentTitle !== null && DocumentTitle.length > 100 )? DocumentTitle.substring(0, 100) + "..." : DocumentTitle } 
                </span>
            </h5> 
            <span className="document_pdf_link" onClick={()=> window.open(`https://www.nrc.gov${result.document.URL}`,'winname','directories=left, titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=1000 height=450px' )}><img className="document-pdf mr-2" src="images/Document-pdf.svg" width="15" alt="document-pdf"/>https://www.nrc.gov{result.document.URL}</span>
            <div className="relevance_score">
                <ul> 
                    <li>Relevance score: {result.score}</li> 
                    <li><span className="pdf-detail" onClick={ () => openDetailPopup(result.document.metadata_storage_name)}>Details</span></li>
                </ul> 
            </div>  
        </div>    
    );
  });   
  let beginDocNumber = Math.min(props.skip + 1, props.count);
  let endDocNumber = Math.min(props.skip + props.top, props.count);
  if (props.count > 0) 
  {
    return (
      <div className="position-relative">
        {isLoading && 
          <div className="col-md-12 loader-outer position-absolute text-center">
          <CircularProgress className="mt-5 " />
        </div>
        }
        <p className="results-info">Showing {beginDocNumber}-{endDocNumber} of {props.count.toLocaleString()} results</p>
        <div className="row">
          {results}
        </div>  
        {singledocument && <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Result Details <span onClick={handleClose} className="float-right" color="primary"><img src='/images/Close.svg' width="15" alt="img"/></span></DialogTitle>            
            <DialogContent> 
              <div className="popup-body-content">
                    <div className="popup-content-item-outer">
                        <h4>Document Tilte</h4>
                        {(singledocument.DocumentTitle)?singledocument.DocumentTitle : "---"}
                    </div>

                    <div className="popup-content-item-outer">
                        <h4>Document Body</h4>
                        <div className="document-body">
                            <div className="document-body-items">
                                {singledocument.content}
                            </div> 
                        </div>
                    </div>
                    <div className="px-3">
                        <div className="row row-cols-2">
                            <div className="popup-content-item-outer">
                                <h4>Accession Number</h4>
                                {(singledocument.Accessionnumber)?singledocument.Accessionnumber : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Case Reference Number</h4>
                                {(singledocument.CaseReferenceNumber)?singledocument.CaseReferenceNumber : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Package Number</h4>
                                {(singledocument.PackageNumber)?singledocument.PackageNumber : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Author Affiliation</h4>
                                {(singledocument.AuthorAffiliation)?singledocument.AuthorAffiliation : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Address Affiliation</h4>
                                {(singledocument.AddresseeAffiliation)?singledocument.AddresseeAffiliation:"---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Last Modified Date</h4>
                                {changeDate(singledocument.lastmodified)}
                            </div>
                        </div>
                        <div className="row row-cols-2">
                            <div className="popup-content-item-outer">
                                <h4>Licecnse Number</h4>
                                {(singledocument.LicenseNumber)?singledocument.LicenseNumber : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Docket Number</h4>
                                {(singledocument.DocketNumber)?singledocument.DocketNumber : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Author Name</h4>
                                {(singledocument.AuthorName)?singledocument.AuthorName : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Author Address</h4>
                                {(singledocument.AddresseeName)?singledocument.AddresseeName : "---"}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>Document Date</h4>
                                {changeDate(singledocument.DocumentDate)}
                            </div>
                            <div className="popup-content-item-outer">
                                <h4>File Name</h4>
                                {(singledocument.filename)?singledocument.filename : "---"}
                            </div>
                        </div>
                    </div>
                </div> 
            </DialogContent>
          </Dialog>
        }
    </div>        
    );
  }
  else{
    return (
        <div className="result_not_found">
            <div className="result-logo pb-0"> 
                <img src="/images/TekXlerate-Logo.png" width="300" alt="logo"/> 
            </div>
            <p className="results-info">
              <span className="not_topheading">Your Search returned no results...</span>
              <span className="not_botton_heading">Please check your spelling or expand your search filter </span>
            </p>
        </div>
      )
  }
};
