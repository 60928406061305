import React from "react";
import { useHistory } from "react-router-dom";
import SearchBar from '../../components/SearchBar/SearchBar';
import AppHeaderHome from "../../components/AppHeader/AppHeaderHome";
import "../../pages/Search/Search.css";
import "./Home.css";

export default function Home() {
  const history = useHistory();
  const navigateToSearchPage = (q) => {
    if (!q || q === '') {
      q = '*'
    }
    history.push('/search?q=' + q);
  }

  return (
    <main className="main main--home">
       <AppHeaderHome />
      <div className="container">
        <div className="row home-search nrc-search m-0"> 
          <img className="logo pb-5" src="/images/TekXlerate-Logo.png"  width="300" alt="Cognitive Search"></img> 
          <SearchBar postSearchHandler={navigateToSearchPage}></SearchBar>
        </div>
      </div>
    </main>
  );
};
